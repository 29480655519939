<template>
  <!-- 商品詳情 SR -->
  <div class="page-start">
    <app-carousel v-if="shop_banners.length" :list="shop_banners" :is_thumbnail="false" />
    <div class="container">
      <!--
      <div class="news" v-if="'news' in shop_other">
        {{ shop_other.news }}
      </div>
      -->
      <div
        class="news"
        v-if="'news' in shop_other"
        v-html="shop_other.news.replace(/\n/g, '<br />\n')"
      ></div>
      <div class="social-links">
        <div class="row row-cols-5 justify-content-center no-gutters">
          <div class="col" v-if="'website' in shop_other && shop_other.website != ''">
            <a :href="shop_other.website" target="_blank">
              <img src="../assets/images/start-social01.png" alt="Website" />
              <p>Website</p>
            </a>
          </div>
          <div class="col" v-if="'facebook' in shop_other && shop_other.facebook != ''">
            <a :href="shop_other.facebook" target="_blank">
              <img src="../assets/images/start-social02.png" alt="Facebook" />
              <p>Facebook</p>
            </a>
          </div>
          <div class="col" v-if="'instagram' in shop_other && shop_other.instagram != ''">
            <a :href="shop_other.instagram" target="_blank">
              <img src="../assets/images/start-social03.png" alt="Instagram" />
              <p>Instagram</p>
            </a>
          </div>
          <div class="col" v-if="'youtube' in shop_other && shop_other.youtube != ''">
            <a :href="shop_other.youtube" target="_blank">
              <img src="../assets/images/start-social04.png" alt="YouTube" />
              <p>YouTube</p>
            </a>
          </div>
          <div class="col" v-if="'email' in shop_other && shop_other.email != ''">
            <a :href="shop_other.email" target="_blank">
              <img src="../assets/images/start-social05.png" alt="email" />
              <p>E-mail</p>
            </a>
          </div>
        </div>
      </div>
      <div class="information mb-3">
        <ul class="list-unstyled">
          <li>
            <span class="material-icons-outlined">storefront</span>
            <span style="visibility: hidden; width: 0; height: 0; font-size: 0;">a</span>
            {{ title_name }}
          </li>
          <li>
            <span class="material-icons-outlined">receipt_long</span>
            <span style="visibility: hidden; width: 0; height: 0; font-size: 0;">a</span>
            {{ tax_id }}
          </li>
          <li v-if="shop_address != ''">
            <span class="material-icons-outlined">place</span>
            <span style="visibility: hidden; width: 0; height: 0; font-size: 0;">a</span>
            {{ shop_city }}{{ shop_cityarea }}{{ shop_address }}
          </li>
          <li v-if="shop_phone != ''">
            <span class="material-icons-outlined">call</span>
            <span style="visibility: hidden; width: 0; height: 0; font-size: 0;">a</span>
            {{ shop_phone }}
          </li>
          <template v-if="'custom_url' in shop_other">
            <template v-for="custom_url in shop_other.custom_url" :key="custom_url.name">
              <li v-if="custom_url.name != ''">
                <span class="material-icons-outlined">share</span>
                <a :href="custom_url.url" target="_blank">{{ custom_url.name }}</a>
              </li>
            </template>
          </template>
        </ul>
      </div>
    </div>
    <div class="container bg-light">
      <div class="open-list">
        <ul class="list-unstyled">
          <li v-if="onIsShopType(shop_type, 'diner')">
            <span class="label">取餐方式：</span>
            <span class="text-primary">{{ onGetShopSetting("diner") }}</span>
          </li>
          <li v-if="onIsShopType(shop_type, 'store')">
            <span class="label">取貨方式：</span>
            <span class="text-primary">{{ onGetShopSetting("store") }}</span>
          </li>
          <li>
            <span class="label">{{ onGetShopTitle(shop_type) }}</span>
            <ul class="open-time list-unstyled">
              <template v-for="(weeks, weeks_key) in shop_open_hours" :key="'hour_' + weeks_key">
                <li v-if="weeks.status">
                  {{ state.weeksName[weeks.day - 1] }}
                  <span
                    v-for="(hour, hour_key) in weeks.hours"
                    :key="'hour_' + hour_key"
                  >
                    {{ hour.open }} ~ {{ hour.close }}
                    <span
                      v-if="hour_key !== weeks.hours.length - 1"
                    >、</span>
                  </span>
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- 商品詳情 ED -->
  <!--選單 SR-->
  <nav class="checkout-menu">
    <div class="row no-gutters justify-content-center w-100 fixed-bottom bg-light">
      <div class="col px-3" v-if="onIsShopType(shop_type, 'diner')">
        <a href="/home" class="btn bg-main btn-block shadow text-white my-2" id="optionClose">立即點餐</a>
      </div>
      <div class="col px-3" v-if="onIsShopType(shop_type, 'store')">
        <a href="/shop/category" class="btn bg-main btn-block shadow text-white my-2">零售商店</a>
      </div>
    </div>
  </nav>
  <!--選單 END-->
</template>
<script>
import { inject, reactive } from "vue";
import AppCarousel from "@/components/AppCarousel.vue";

export default {
  name: "StoreInfo",
  components: {
    AppCarousel,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    console.log("Store 開始～～～");
    const shop_setting = inject("shop_setting");
    const shop_shipping = inject("shop_shipping");
    const shop_open_hours = inject("shop_open_news");
    const shop_type = inject("shop_type");
    const shop_name = inject("shop_name");
    const shop_city = inject("shop_city");
    const shop_cityarea = inject("shop_cityarea");
    const shop_address = inject("shop_address");
    const shop_phone = inject("shop_phone");
    const shop_other = inject("shop_other");
    const shop_banners = inject("shop_banners");
    const title_name = inject("title_name");
    const tax_id = inject("tax_id");
    const state = reactive({
      weeksName: [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期日",
      ],
      loading: true,
    });

    //methods
    const onIsShopType = (type, check_type) => {
      if (check_type == "diner") {
        if (
          type === "okshop+df" ||
          type === "okshop+d" ||
          type === "okshop+od" ||
          type === "okshop+dfs" ||
          type === "okshop+ds" ||
          type === "okshop+ods"
        ) {
          return true;
        }
      }
      if (check_type == "store") {
        if (
          type === "okshop+s" ||
          type === "okshop+dfs" ||
          type === "okshop+ds" ||
          type === "okshop+os" ||
          type === "okshop+ods"
        ) {
          return true;
        }
      }
      return false;
    };
    const onGetShopTitle = (type) => {
      if (type === "okshop+df" || type === "okshop+d") { // 餐飲
        return "商店營業時間如下："; // "網路商店24小時營業：";
      } else { // 零售
        return "網路商店24小時營業，實體商店營業時間如下：";
      }
    };
    const onGetShopSetting = (type) => {
      let msg = "";
      if (type == "diner") {
        if (shop_setting.value.order.status === 1) msg += "現場點餐";
        if (shop_setting.value.here.status === 1) {
          if (msg !== "") msg += "、";
          msg += "預約內用";
        }
        if (shop_setting.value.togo.status === 1) {
          if (msg !== "") msg += "、";
          msg += "到店自取";
        }
        if (shop_setting.value.delivery.status === 1) {
          if (msg !== "") msg += "、";
          msg += "店家外送";
        }
        // if (shop_setting.value.for_here === 1) msg += "內用";
        // if (shop_setting.value.to_go === 1) {
        //   if (msg !== "") msg += "、";
        //   msg += "自取";
        // }
        // if (shop_setting.value.order_in === 1) {
        //   if (msg !== "") msg += "、";
        //   msg += "外送";
        // }
      }
      if (type == "store") {
        if (shop_shipping.value.instore.status === 1) msg += "自取";
        if (shop_shipping.value.delivery.status === 1) {
          if (msg !== "") msg += "、";
          msg += "宅配";
        }
      }
      return msg;
    };

    return {
      shop_setting,
      shop_open_hours,
      shop_type,
      shop_name,
      shop_city,
      shop_cityarea,
      shop_address,
      shop_phone,
      shop_other,
      shop_banners,
      state,
      title_name,
      tax_id,
      onIsShopType,
      onGetShopTitle,
      onGetShopSetting,
    };
  },
};
</script>
