<template>
  <div class="app-carousel">
    <div class="top">
      <el-carousel
        :interval="5000"
        arrow="always"
        ref="carousel"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <img :src="item.image_url" alt="background" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="bottom" v-if="is_thumbnail">
      <div class="wrapper">
        <div
          class="bottom-image"
          :style="currentIndex === index && 'opacity: 1;'"
          v-for="(item, index) in list"
          :key="index"
          @click="imgClick(index)"
        >
          <img :src="item.image_url" alt="button" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from "vue";

export default {
  props: {
    list: Array,
    is_thumbnail: { type: Boolean, default: true },
  },
  setup(props) {
    const carousel = ref(null);
    const currentIndex = ref(0);

    // const currentList = computed(() => {
    //   return [...props.list].sort(function (a, b) {
    //     return a.sort - b.sort;
    //   });
    // });

    watchEffect(
      () => {
        const activeIndex = carousel.value.data.activeIndex;
        if (activeIndex >= 0) {
          currentIndex.value = activeIndex;
        }
      },
      {
        flush: "post",
      }
    );

    const imgClick = (index) => {
      carousel.value.setActiveItem(index);
    };

    return {
      // currentList,
      imgClick,
      carousel,
      currentIndex,
    };
  },
};
</script>

<style lang="scss" scoped>
.app-carousel::v-deep .el-carousel {
  .el-carousel__container {
    padding-top: 100%;
    height: inherit;
  }
  @media (min-width: 640px) {
    .el-carousel__container {
      padding-top: inherit;
      height: 40vh;
      max-height: 300px;
    }
  }
  .el-carousel__item {
    h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      text-align: center;
      color: red;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.bottom {
  overflow-x: auto;
  margin-top: 10px;
  .wrapper {
    display: flex;
    flex-wrap: nowrap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
    overflow: auto;

    .bottom-image {
      opacity: 0.7;
      width: 23%;
      flex-shrink: 0;
      img {
        border-radius: 4px;
      }
    }
  }
}
</style>
